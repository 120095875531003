import React, { useState } from "react";
import { UserClaim } from "../../common/common.typings";
import { getUserEmail } from "../../helpers";
import { useGetUserProfile } from "../../queries/users";
import { PageForbidden } from "./PageForbidden";
import { PageNotAuthorized } from "./PageNotAuthorized";

interface AuthenticationProps {
  children: React.ReactNode;
}
export const Authentication: React.FC<AuthenticationProps> = ({ children }: AuthenticationProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userClaims, setUserClaims] = useState<UserClaim[]>([]);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const { data: cerberusResponse, status, error } = useGetUserProfile(getUserEmail() ?? "", isLoading);

  React.useEffect(() => {
    switch (status) {
      case "error":
        setIsLoading(false);
        setIsAuthorized((error as Response)?.status !== 401);
        break;
      case "success":
        setIsLoading(false);
        setIsAuthorized(true);
        setUserClaims(cerberusResponse?.validatedRequestCProfit?.claims ?? []);
        break;
      default:
        setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const doesUserHaveClaims = (): boolean => {
    return userClaims.find(x => x.domainId === "datacollect") !== undefined;
  }



  if (isLoading) {
    return <div className="spinner-grow spinner-grow-sm" role="status">
      Loading...
    </div>;
    
  }
  if (isAuthorized) {
    if (doesUserHaveClaims()) {
      return <>{children}</>;
    }
    return <PageForbidden />;
  }
  return <PageNotAuthorized />;
}