import * as React from "react";
import { Modal } from "../../common/Modal/Modal";
import { SupplierDto } from "../common/suppliers.typings";
import { Select } from "@sgbs-ui/core";
import { FileUploader } from "../../common/FileUploader/FileUploader";
import { Label } from "../../common/ReadOnlyFields/Label";
import { FileUploadDto } from "../common/upload.typings";
import { useCheckLastSuccessUpload, useUploadFile } from "../../../queries/upload";
import { displayUploadFrequency } from "./FileUploadModal.helper";
import { ReferentialNumber } from "../../../common/common.typings";
import { useGetAuthorizedSuppliers } from "../common/hooks/useGetAuthorizedSuppliers";
import { computeMonths, computeYears } from "../common/common.helpers";

export interface Props {
  show: boolean;
  onClose: () => void;
  onSuccessFileUpload: () => void;
}

export const EMPTY_STATE: FileUploadDto = {
  supplier: { id: 0, supplierMnemo: "Select supplier", supplierLabel: "", uploadFrequency: "" },
  accountingPeriodMonth: 0,
  accountingPeriodYear: 0,
  file: null
};

export const FileUploadModal: React.FC<Props> = ({ show, onClose, onSuccessFileUpload }: Props) => {
  const [years, setYears] = React.useState<ReferentialNumber[]>([]);
  const [months, setMonths] = React.useState<ReferentialNumber[]>([]);
  const [fileUploadModel, setFileUploadModel] = React.useState<FileUploadDto>(EMPTY_STATE);
  const {isLoading, isSuccess, mutate } = useUploadFile();
  const [suppliers] = useGetAuthorizedSuppliers();
  const [displayLastSuccessUploadFile, setDisplayLastSuccessUploadFile] = React.useState<boolean>(false);
  const { data: lastSuccessUploadDto, refetch } = useCheckLastSuccessUpload(fileUploadModel, false);

  const isModelValid = () => {
    return fileUploadModel.supplier.id !== 0 &&
    fileUploadModel.accountingPeriodMonth !== 0 &&
    fileUploadModel.accountingPeriodYear !== 0 &&
    fileUploadModel.file !== null;
  }

  const handleCloseModal = () => {
    setFileUploadModel(EMPTY_STATE);
    setDisplayLastSuccessUploadFile(false);
    onClose();       
  };

  const handleSubmit = () => {
    if (isModelValid()) {
      mutate(fileUploadModel);
    }
    if (isLoading) {
      handleCloseModal();
    }
  };

  React.useEffect(() => {
    if (fileUploadModel.supplier.id !== 0 && fileUploadModel.accountingPeriodMonth !== 0 && fileUploadModel.accountingPeriodYear !== 0) {
      refetch();
      setDisplayLastSuccessUploadFile(lastSuccessUploadDto?.exist ?? false);
    }      
  }, [fileUploadModel.accountingPeriodMonth, fileUploadModel.accountingPeriodYear, fileUploadModel.supplier, lastSuccessUploadDto, refetch]);
   
  React.useEffect(() => {
    const newMonths = computeMonths(fileUploadModel.supplier.uploadFrequency);
    setMonths(newMonths);
    setFileUploadModel(f => { f.accountingPeriodMonth = newMonths[0]?.id ?? 0; return f;});
  }, [fileUploadModel.supplier.uploadFrequency]);

  
  React.useEffect(() => {
    if (show) {
      const newYears = computeYears();
      setYears(newYears);
      setFileUploadModel(f => { f.accountingPeriodYear= newYears[0].id; return f; });    
    }    
  }, [show]);  

  React.useEffect(() => {
    if (isSuccess) {
      onSuccessFileUpload();
    }
  }, [onSuccessFileUpload, isSuccess]);
  

  const onSelectFileChange = (file: File): void => {
    setFileUploadModel({
      ...fileUploadModel,
      file: file
    });   
    
  }

  const onFileRemove = (): void => {
    setFileUploadModel({
      ...fileUploadModel,
      file: null
    });
  }

  return (
    <>
      {suppliers && (
        <Modal
          size="xl"
          title="Upload a file"
          cancelLabel="Cancel"
          submitLabel="Upload"
          show={show}
          backdropClose={true}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
          onSubmit={handleSubmit}
          isSubmitValid={isModelValid()}
          isLoading={isLoading}
        >
          <div className="row">
            <div className="col-md-3 form-group">
              <Label displayLabel="Supplier" htmlFor="inputState " required={true} />
            </div>
            <div className="col-md-3 form-group text-left">
              <Select.SingleSelect<SupplierDto>
                idField="id"
                labelField="supplierMnemo"
                items={suppliers}
                showClearButton={false}
                selectedItem={fileUploadModel.supplier}
                onChange={e => {
                  if (e != null) {
                    setFileUploadModel({
                    ...fileUploadModel,
                    supplier: e
                  }); 
                  }     
                }}
              />
            </div>
            {fileUploadModel.supplier.id !== 0 &&  
              <div className="col-md-6 text-left">
                <div className="form-group mb-0">
                  <label>Upload Frequency :</label> {displayUploadFrequency(fileUploadModel.supplier.uploadFrequency)}
                </div>
                <div className="form-group mb-0">
                  <label>Supplier Label :</label> {fileUploadModel.supplier.supplierLabel}
                </div>
              </div>
            }
          </div>
          <div className="row">
            <div className="col-md-3 form-group">
              <Label displayLabel="Accounting Period" htmlFor="inputState " required={true} />
            </div>
            <div className="col-md-3 form-group text-left">
              <Select.SingleSelect<ReferentialNumber>
                idField="id"
                labelField="label"
                items={years}
                showClearButton={false}
                selectedItem={years.find(x => x.id === fileUploadModel.accountingPeriodYear) ?? { id: 0, label: "" }}
                onChange={e => {
                  if (e != null) {
                    setFileUploadModel({
                      ...fileUploadModel,
                      accountingPeriodYear: e.id
                    });
                  }
                }}
              />
            </div>
            <div className="col-md-3 form-group text-left">
              <Select.SingleSelect<ReferentialNumber>
                idField="id"
                labelField="label"
                items={months}
                showClearButton={false}
                selectedItem={months.find(x => x.id === fileUploadModel.accountingPeriodMonth) ?? { id: 0, label: "" }}
                onChange={e => {
                  if (e != null) {
                    setFileUploadModel({
                      ...fileUploadModel,
                      accountingPeriodMonth: e.id
                    });
                    
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 form-group">
              <Label displayLabel="Upload a new file" htmlFor="inputState " required={true} />
            </div>
            <div className="col-md-6 form-group">
              <FileUploader
                isValid={true}
                onFileSelectSuccess={file => onSelectFileChange(file)}
                onFileRemove={() => onFileRemove()}
                fileAlreadyExist={
                  fileUploadModel.file != null ? fileUploadModel.file.name : null
                }
                placeholder="Drag file"
              />
            </div>
          </div>
          <>{displayLastSuccessUploadFile &&
            <div className="row"> 
              <div className="col-md-12">
                <Label displayLabel="File already uploaded for same accounting period and same supplier. This new file will cancel and replace previous one." className="text-warning" />
              </div>
            </div>
          }</>
        </Modal>
      )}
    </>
  );
};
