import React from "react";
import { FormattedMessage } from "react-intl";
import { Navbar, NavbarBrand, Nav, NavItem, NavbarToggler, Collapse } from "reactstrap";
import { NavLink } from "react-router-dom";
import { AccountCenter } from "./AccountCenter";

interface HeaderState {
  collapsed: boolean;
}

export class Header extends React.Component<{}, HeaderState> {
  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState(({ collapsed }) => ({
      collapsed: !collapsed,
    }));
  };

  render() {
    return (
      <header>
        <Navbar
          light={true}
          expand="xl"
          className="bg-white d-flex justify-content-between align-content-center border-bottom"
        >
          <NavbarBrand href="/">
            <img
              src="https://shared.sgmarkets.com/assets/images/socgen_logo_full.svg"
              height="32"
              alt="SG Markets"
              className="d-none d-md-block"
            />
            <img
              src="https://shared.sgmarkets.com/assets/images/socgen_logo_muted.svg"
              height="32"
              alt="SG Markets"
              className="d-block d-md-none"
            />
          </NavbarBrand>

          <NavbarToggler onClick={this.toggle} className="mr-auto">
            <FormattedMessage id="menu.title" />
            <i className="icon icon-md text-secondary">arrow_drop_down</i>
          </NavbarToggler>

          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink to="/" className="nav-link">
                  <FormattedMessage id="menu.home" />
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          <AccountCenter />
          <div className="d-none d-md-block overflow-visible ml-3">
            <img src="../../../logo_sgm_C-Profit.png" alt="Cprofit-sgmarket-logo" />
          </div>
        </Navbar>
      </header>
    );
  }
}
